export const userProfile = `
    phoneNumber
    firstName
    lastName
    schedulingLink
    businessType
    businessName
    twitterProfile
    businessURL
    linkedinProfile
    linkedinURL
    jobTitle
    ipAddress
    countryIsoCode
    countryName
`;

export const referralPartner = `
    _id
    name
    url
    imageUrl
    created
    updated
    referralLink
    active
    promoCode
    numProjectsReferred
    emailsReferred
`;

export const proposalMessage = `
    _id
    projectId
    created
    enterpriseOrganizationId
    index
    content
    userId
    userEmail
    fromUser
    fromEnterprise
`;

export const loadingStatus = `
chat
last_chat
architecture
last_architecture
recommendedTechStack
recommendedJobRoles
last_recommendedTechStack
last_recommendedJobRoles
sow_mvp
last_sow_mvp
sow_custom
last_sow_custom
jira
last_jira
uiview
last_uiview

`;

export const enterprise_subscription_link = `
    url
    tier
    price
    priceId
    productId
    duration
    trial
`;

export const jira = `
    percentage
    version
    loadingText
    projectURL
`;

export const message = `
_id
nodeId
accentuatedContent
batchScrape {
    url
    title
    description
}
batchScrapeLoadingText
index
content
role
userEmail
datetime
completed
username
image_url
fileKeyS3
tagged

`;

export const messageUIView = `
index
content
role
userEmail
datetime
completed
username
image_url
fileKeyS3
tagged
nodeId
`;

export const recommended_tech_stack = `
    name
    description
    useFor
    link
    reason
    helpfulLinks {
        url
        description
    }
    alternatives {
        name
        link
        description
    }
`;

export const recommended_job_roles = `
    title
    responsibilities
    qualifications
    requiredSkills
`;

export const graph = `
nodes {
    __typename @skip(if: true)
    _id
    id
    graphId
    label
    hours
    description
    reactPage
    created
}
edges {
    __typename @skip(if: true)
    _id
    source
    target
    description
}
_id
side
type
version
`;

export const existingProjectContext = `
    _id
    fileKeyS3
    type
    title
    text
    image_url
    summary
`;

export const enterprise_advanced_config = `
    apiKey
    calendlyLink
    websiteURL
    activateMeetings
    logo
    chatAgentLogo
    introductionLogo
    chatAgentName
    widgetCompletionRedirectURL
    projectCreationTitle
    initialChatMessage
    clientEmail
    disableNewProjectEmails
    disableViewProjectPlanWidget
    disableHoursInShareLink
    requirePhoneNumber
    requireBusinessURL
    hideBudgetInWidget
    disableShareLink
    disableLeadGenEmails
    disablePremiumLeadGenEmails
    onlyNewProject
    scheduleMeetingButtonText
    scheduleMeetingHelperText

    address1
    address2
    city
    state
    zip
    country {
        name
        code
    }
    phone
    reviewPageLink
    isPhoneVerified
    linkedInLink
    githubLink
    description
    techStackItems
    industriesServed
    portfolioProjects
    primaryColor
    secondaryColor
    textColor
    backgroundColor
`;

export const knowledgeBaseFile = `
    _id
    name
    fileKeyS3
    created
    activated
    activatedAt
    loading
    loadingAt
    customSowFormat
    type
`;

export const enterpriseUsers = `
    enterpriseUsers {
        userEmail
        title
        role
        skills {
            title
            level
        }
        hourlyRate
        created
    }
`;

export const defaultTechStackItem = `
    _id
    name
    initialName
    link
    description
`;

export const sow = `
    projectId
    created
    title
    version
    executiveSummary
    goalsAndObjectives {
        description
        objectives
    }
    successMetrics {
        name
        description
    }

    coreFunctionalities {
        name
        description
        priority
    }
    stakeholders {
        title
        description
    }
    techStack {
        name
        useFor
    }
    milestones {
        description
        id
        __typename @skip(if: true)
        stories {
            _id
            __typename @skip(if: true)
            id
            active
            architectureNodeIdCitations
            description
            title
            nodeId
            acceptance_criteria
            priority
            subTasks {
                _id
                __typename @skip(if: true)
                id
                storyId
                projectId
                index
                description
                complexityEstimate
                hours
                roles {
                    name
                    shortHand
                }
                architectureNodeIdCitations 
                techStackItemCitations
                frontendPageCitations
            }
        }
    }
    latestTechStackVersion
    latestGraphVersion
    lastUpdatedChatIndex
    enterpriseOrganizationId
    type
    completed
    content
    internal_version
    userContent
    cursorRules
    predevPlan
    updatedAt
`;

export const thumbsRating = `
    id
    rating
    metadata
`;

export const enterprise = `
	_id
	name
	isAdmin
	created
	numUsersLeft
    widgetManagementActivated
	apiKey
	apiDomains {
		_id
		url
		index
		created
	}
	${enterpriseUsers}
	subscriptionLinks {
		${enterprise_subscription_link}
	}
    defaultTechStackItems {
        ${defaultTechStackItem}
    }
	subscriptionStatus {
		status
		tier
		price
		created_at
		current_period_end
		duration
		current_period_start
		cancel_at_period_end
	}
    ${enterprise_advanced_config}
    knowledgeBaseFiles {
        ${knowledgeBaseFile}
    }
    leadGenData {   
        leadGenCreditsLeft
        leadGenCreditsPurchased {
            numCredits
            createdAt
            purchaseType
        }
    }
    leadGenSubscriptionStatus {
        status
        tier
        price
        created_at
        current_period_end
        duration
    }
`;

export const proposal = `
    projectId
    enterpriseOrganizationId
    enterpriseSchedulingLink
    enterpriseOrganization {
        ${enterprise}
    }
    created
    updated
    chatHistory {
        ${proposalMessage}
    }
`;

export const leaderboardComment = `
    _id
    userId
    leaderboardEntryId
    parentCommentId
    content
    upvotes
    createdAt
    updatedAt
    isVotedByUser
    isDeleted
    isMyComment
    replies {
      _id
      userId
      leaderboardEntryId
      parentCommentId
      content
      upvotes
      createdAt
      updatedAt
      isVotedByUser
      isDeleted
      isMyComment
      replies {
        _id
        userId
        leaderboardEntryId
        parentCommentId
        content
        upvotes
        createdAt
        updatedAt
        isVotedByUser
        isDeleted
        isMyComment
        replies {
          _id
          userId
          leaderboardEntryId
          parentCommentId
          content
          upvotes
          createdAt
          updatedAt
          isVotedByUser
          isDeleted
          isMyComment
          replies {
            _id
            userId
            leaderboardEntryId
            parentCommentId
            content
            upvotes
            createdAt
            updatedAt
            isVotedByUser
            isDeleted
            isMyComment
            replies {
              _id
              userId
              leaderboardEntryId
              parentCommentId
              content
              upvotes
              createdAt
              updatedAt
              isVotedByUser
              isDeleted
              isMyComment
              replies {
                _id
                userId
                leaderboardEntryId
                parentCommentId
                content
                upvotes
                createdAt
                updatedAt
                isVotedByUser
                isDeleted
                isMyComment
              }
            }
          }
        }
      }
    }
`;

export const leaderboardEntry = `
    _id
    projectId
    name
    userId
    submittedAt
    upvotes
    commentCount
    description
    comments {
        ${leaderboardComment}
    }
    isVotedByUser
    isMyEntry
`;

export const leaderboard = `
    entries {
        _id
        projectId
        name
        userId
        submittedAt
        upvotes
        commentCount
        description
        isVotedByUser
    }
    skip
    limit
    totalEntries
    startDate
    endDate
`;

export const leadGenProjectsList = `
projects {
        _id
        name
        created
        userEmail
        country {
            name
            code
        }
        updated
        soloTier
        premiumLeadGenEnabled
        budget
        phoneNumber
        businessURL
        schedulingLink
        leadGenEnabled
        emailVerified
        urgency
        messageCount
        matchWithDev
        leadGenEmailRevealed
        referralPartner {
            ${referralPartner}
        }
        leadGenMeetingTriggered
        execSummary
        userProfile {
            ${userProfile}
        }
        industryTags
        projectTypeTags
    }
    totalProjects
`;

export const projectList = `
    projects {
        _id
        name
        __typename @skip(if: true)
        screenshotData {
            desktop {
                url
                fileKeyS3
                order
                nodeId
            }
            mobile {
                url
                fileKeyS3
                order
                nodeId
            }
        }
        created
        userEmail
        country {
            name
            code
        }
        updated
        soloTier
        premiumLeadGenEnabled
        budget
        phoneNumber
        businessURL
        creationMethod
        schedulingLink
        leadGenEnabled
        emailVerified
        userId
        urgency
        messageCount
        matchWithDev
        leadGenEmailRevealed
        referralPartner {
            ${referralPartner}
        }
        leadGenMeetingTriggered
        execSummary
        userProfile {
            ${userProfile}
        }
        industryTags
        projectTypeTags
        tagsUpdatedAt
        numProposals
        proposals {
            ${proposal}
        }
    }
    totalProjects
`;

export const stripeSubscription = `
    status
    tier
    price
    created_at
    current_period_end
    duration
    current_period_start
    cancel_at_period_end
    discount {
        coupon {
            id
            name
            percent_off
            amount_off
            duration
            duration_in_months
        }
    }
    products {
        productId
        tier
        quantity
        price
        interval
        name
        description
    }
`;

export const uiView = `
    _id
    created
    projectId
    nodeId
    version
    tested
    passed
    status
    deploymentUrl
    updatedRoute
    projectMessageCount
    currentPlan
    lastUpdated
    filesToEdit
    desktopScreenshots {
        url
        fileKeyS3
        order
        nodeId
    }
    mobileScreenshots {
        url
        fileKeyS3
        order
        nodeId
    }
`;

export const uiViewFilesAndErrors = `
    errors {
        message
        path
    }
    __typename @skip(if: true)
    files {
        __typename @skip(if: true)
        _id
        created
        projectId
        nodeId
        uiViewId
        plan {
        __typename @skip(if: true)
            reasoning
            action
            code
            created
        }
        code
        path
        version
        lastVersion
        lastUpdated
        status
    }
`;

export const project = `
_id
name
soloTier
industryTags
__typename @skip(if: true)
screenshotData {
    desktop {
        url
        fileKeyS3
        order
        nodeId
    }
    mobile {
        url
        fileKeyS3
        order
        nodeId
    }
}
projectTypeTags
tagsUpdatedAt
schedulingLink
phoneNumber
emailVerified
premiumLeadGenEnabled
urgency
businessURL
numProposals
referralPartner {
    ${referralPartner}
}
proposals {
    ${proposal}
}
budget
creationMethod
country {
 name
 code
}
type
created
updated
execSummary
userEmail
matchWithDev
firstGeneration
hasExistingContext
internal_version
skip
fileKeyS3
meetingRequested
latestTechStackVersion
prices
messageCount
isSubscribed
chatHistory {
    ${message}
}
existingProjectContexts {
    ${existingProjectContext}
}
sow_mvp_up_to_date
sow_custom_up_to_date
uiview_up_to_date
recommendedTechStack {
    ${recommended_tech_stack}
}
recommendedJobRoles {
    ${recommended_job_roles}
}
graphs {
    ${graph}
}
techStackVersions 
graphVersions
uiviewVersions
sowVersions {
    mvp
    custom
}
theme {
    aesthetic
    tailwindConfig
}
leadGenEnabled
leadGenEmailRevealed
leadGenMeetingTriggered
deploymentUrl
userProfile {
    ${userProfile}
}
`;
